import Cookies from 'js-cookie'
import store from "@/store";

const TokenKey = store.getters.sys_name+"_token"

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}