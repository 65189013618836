import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/login/login")
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/login")
  },
  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true
  },
  //首页
  {
    path: "/practice",
    name: "practice",
    component: () =>import("@/views/practice/index"),
    meta: {
      requireAuth:true,
    }
  },
  //词书页
  {
    path: "/book",
    name: "book",
    component: () =>import("@/views/practice/book"),
    meta: {
      requireAuth:true,
    }
  },
  //单元页
  {
    path: "/unit",
    name: "unit",
    component: () =>import("@/views/practice/unit"),
    meta: {
      requireAuth:true,
    }
  },
  //单元全部单词页
  {
    path: "/unit_detail",
    name: "unit_detail",
    component: () =>import("@/views/practice/unit_detail"),
    meta: {
      requireAuth:true,
    }
  },
  //问题卡片页
  {
    path: "/question_card",
    name: "question_card",
    component: () =>import("@/views/practice/question_card"),
    meta: {
      requireAuth:true,
    }
  },
  //设置抽题规则页
  {
    path: "/get_question_config",
    name: "get_question_config",
    component: () =>import("@/views/practice/get_question_config"),
    meta: {
      requireAuth:true,
    }
  },
  //开始练习
  {
    path: "/answer",
    name: "answer",
    component: () =>import("@/views/practice/answer"),
    meta: {
      requireAuth:true,
    }
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
];

const router = new VueRouter({
  base:"/f/",
  // mode: 'history',
  routes
});

export default router;