import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import {getToken} from "@/utils/auth";

document.title="职业技能考试模拟训练系统"

//预生产加载vconsole
// import Vconsole from "vconsole";
// if (process.env.NODE_ENV == "development") {
//   const vConsole = new Vconsole();
//   console.log(vConsole);
// }

//进入页面之前判断用户是否登录
router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) { //判断该路由是否需要登录权限
    if (getToken()) { // 获取用户登录信息
      next();
    } else {
      next({
        path: '/login',
        query: {
          redirect: from.fullPath
        } // 将跳转的路由path作为参数，登录成功后跳转到该路由
      })
    }
  } else {
    next();
  }
})

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");