import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    sys_name: "doshare_tea",
    token: "",
    avatar: "",
    name: "",
    all_questions: [],
    now_question: [],
    now_group_idx: -1,
    all_group: 0,
    part_id: 0,
    set_id: 0,
    unit_id: 0,
    book_id: 0
  },
  getters: {
    sys_name: state => state.sys_name,
    token: state => {
      return get_item(state, "token")
    },
    avatar: state => state.avatar,
    name: state => state.name,
    all_questions: state => state.all_questions,
    now_question: state => state.now_question,
    now_group_idx: state => state.now_group_idx,
    all_group: state => state.all_group,
    part_id: state => {
      return state.part_id
    },
    set_id: state => {
      return get_item(state, "set_id")
    },
    unit_id: state => {
      return get_item(state, "unit_id")
    },
    book_id: state => {
      return get_item(state, "book_id")
    }
  },
  mutations: {
    set_token(state, val) {
      set_item(state, "token", val)
    },
    set_all_questions(state, val) {
      state.all_questions = val
    },
    set_now_question(state, val) {
      state.now_question = val
    },
    set_now_group_idx(state, val) {
      state.now_group_idx = val
    },
    set_all_group(state, val) {
      state.all_group = val
    },
    set_part_id(state, val) {
      set_item(state, "part_id", val)
    },
    set_set_id(state, val) {
      set_item(state, "set_id", val)
    },
    set_unit_id(state, val) {
      set_item(state, "unit_id", val)
    },
    set_book_id(state, val) {
      set_item(state, "book_id", val)
    }
  },
  actions: {},
  modules: {}
});

function set_item(state, key, val) {
  state[key] = val
  localStorage.setItem(key, val)
}

function get_item(state, key) {
  if (!state[key]) {
    state[key] = localStorage.getItem(key);
    if (state[key] == undefined) {
      state[key] = "";
    }
  }
  return state[key]
}